import { Volume } from '@/lib/reservoir/reservoir.generated';

export enum VolumeSortOption {
  ALL_TIME = 'allTimeVolume',
  ONE_DAY = '1DayVolume',
  SEVEN_DAYS = '7DayVolume',
  THIRTY_DAYS = '30DayVolume',
}

export const volumeSortOptionKeyMap: { [key in VolumeSortOption]: keyof Volume } = {
  [VolumeSortOption.ALL_TIME]: 'allTime',
  [VolumeSortOption.ONE_DAY]: '1day',
  [VolumeSortOption.SEVEN_DAYS]: '7day',
  [VolumeSortOption.THIRTY_DAYS]: '30day',
};
